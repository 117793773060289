<template>
    <div class="score-history__item">
        <div class="column"><div class="write-on write-on-replenishment">Поповнення</div></div>
        <div class="column"> {{ item.createdAt }}</div>
        <div class="column"></div>
        <div class="column"><div class="write-on-amount"> {{ item.amount }}</div></div>
        <div class="column"> {{ item.currentAmount }}</div>
        <div class="column plug"></div>
        <div class="column plug"></div>
        <div class="column"><span>Джерело:</span> Поповнення системою</div>
        <div class="column"><span>ID транзакції:</span> {{ item.id }}</div>
    </div>
</template>

<script>
export default {
    name: 'RowBlockBuyingInsurance',
    props: {
        item: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    amount: null,
                    createdAt: null,
                    currentAmount: null,
                };
            },
        },
    },
}
</script>

<template>
    <div>
        <filter-block :is-load-xml="condition.idLoadXml"
                              @download-xls="downloadXls()"
                              :is-load-data="condition.isLoadTable"
                              :is-main-history="listQuery.statusId === statusMain"
                              :items="items"
                              @init-orders-list="initList()"
                              ref="filter"></filter-block>
        <div class="list-orders container p-0">
            <div class="tabs-block">
                <ul class="status-tabs">
                    <li class="tab">
                        <a @click="activateTab(statusMain)" v-bind:class="{ active: listQuery.statusId === statusMain }" href="javascript:void(0);">Основні</a>
                    </li>
                    <li v-if="! isPartner()" class="tab">
                        <a @click="activateTab(statusPartners)" v-bind:class="{ active: listQuery.statusId === statusPartners }"
                           href="javascript:void(0);">Розрахунки з партнерами</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="score-history">
            <div class="container p-0">
                <div class="header-block">
                    <ul class="columns-block">
                        <li class="column">Тип операції</li>
                        <li class="column">Дата</li>
                        <li class="column">Точка продажу</li>
                        <li class="column">Сума, грн</li>
                        <li class="column">Залишок, грн</li>
                    </ul>
                </div>
                <div v-if="condition.isLoadTable" class="score-history__list">
                    <row-block-skeleton v-for="index in 6" :key="index"></row-block-skeleton>
                </div>
                <div v-else-if="listQuery.statusId === statusMain" class="score-history__list">
                    <row-block v-for="item in items" :key="item.id" :item="item"></row-block>
                </div>
                <div v-else-if="listQuery.statusId === statusPartners" class="score-history__list">
                    <row-block-partner v-for="item in items" :key="item.id" :item="item"></row-block-partner>
                </div>
            </div>
        </div>
        <div style="text-align: center">
            <pagination v-show="total > 0" :total="total"
                    :page.sync="listQuery.page"
                    :limit.sync="listQuery.limit" @pagination="initList()" />
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { RowBlock, RowBlockSkeleton, RowBlockPartner } from './components';
import FilterBlock  from "./components/FilterBlock.vue";
import { list as listTransaction, partnerList as listPartnerTransaction, excel as downloadExcel, partnerExcel as downloadPartnerExcel } from '@/api/transaction';
import { userMixin, partnerMixin } from '@/mixin';
const FileDownload = require('js-file-download');
import Vue from "vue";

export default {
    name: 'list-score',
    components: {RowBlockSkeleton, RowBlock, Pagination, FilterBlock, RowBlockPartner },
    mixins: [userMixin, partnerMixin],
    // created: function () {
    //     list().;
    // }
    data: function () {
        return {
            total: 1,
            items: [],
            statusMain: 1,
            statusPartners: 2,
            condition: {
                isLoadTable: true,
                idLoadXml: false
            },
            listQuery: {
                statusId: 1,
                page: 1,
                limit: 10,
            }
        }
    },
    mounted: function() { // The hook. Here, Vue has already worked its magic.
        this.initList();
    },
    methods: {
        downloadXls: async function () {
            let response;
            Vue.set(this.condition, 'idLoadXml', true);

            if (this.listQuery.statusId === this.statusMain) {
                response = await downloadExcel(this.getQuery());
            } else {
                response = await downloadPartnerExcel(this.getQuery());
            }

            if (response?.data) {
                FileDownload(response.data, 'score.xlsx');
            }

            Vue.set(this.condition, 'idLoadXml', false);
        },
        initList: async function () {
            let response;
            this.items = [];
            this.condition.isLoadTable = true;

            if (this.listQuery.statusId === this.statusMain) {
                response = await listTransaction(this.getQuery());
            } else {
                response = await listPartnerTransaction(this.getQuery());
            }

            this.items = response.data?.data?.items ?? [];
            this.total = response.data?.data?.total;
            // this.total = data.data?.total;
            this.condition.isLoadTable = false;

            setTimeout(() => {
                let list = document.querySelectorAll('.score-history__item');
                list.forEach((element, index) => {
                    if(index % 2){
                        element.style.background = "#F6F7FB";
                    }
                });
            }, 100);
        },
        clearQuery: function () {
            this.$refs.filter.listQuery = Object.assign({}, this.$refs.filter.listDefaultQuery);
        },
        getQuery: function () {
            return {...this.$refs.filter.listQuery, ...this.listQuery};
        },
        activateTab: function(status) {
            this.$set(this.listQuery, 'statusId', status);
        }
    },
    watch: {
        'listQuery.statusId': function (newValue, oldValue) {
            this.clearQuery();
            return this.initList();
        }
    }
}
</script>
